import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Title for english page",
  "description": "Description for english page",
  "h1": "Lorem ipsum dolor sit amet, consectetur adipiscing",
  "h2": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmo"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ContainerFluid = makeShortcode("ContainerFluid");
const SectionFullWidth = makeShortcode("SectionFullWidth");
const Button = makeShortcode("Button");
const SectionOne = makeShortcode("SectionOne");
const AnimationSmall = makeShortcode("AnimationSmall");
const SectionTwo = makeShortcode("SectionTwo");
const AnimationRubik = makeShortcode("AnimationRubik");
const Animation = makeShortcode("Animation");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ContainerFluid mdxType="ContainerFluid">
      <SectionFullWidth className="py-5" mdxType="SectionFullWidth">
        <h1>{`Index in english`}</h1>
        <Button inline large mdxType="Button">Click here </Button>
        <Button solid color="#cc3837" inline mdxType="Button">Click here </Button>
        <Button medium color="#cc3837" pulse inline mdxType="Button">Click here </Button>
      </SectionFullWidth>
      <SectionOne col={3} className="d-none d-lg-block" style={{
        backgroundColor: "lightgray",
        padding: "4rem 0 8rem 0"
      }} mdxType="SectionOne">
        <AnimationSmall mdxType="AnimationSmall" />
      </SectionOne>
      <SectionTwo col={8} mdxType="SectionTwo">
        <p>{`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec risus dui, eleifend ac facilisis in, pretium in sapien. Morbi a sem eget urna laoreet placerat nec a orci. Vivamus quis lectus sit amet turpis commodo viverra in eget urna. Aenean at feugiat leo.`}</p>
        <p>{`Quisque at sodales ex. Mauris eget ultricies ipsum. Cras vel mollis nisi. Duis sed orci quis elit tempus eleifend ut non nisi. Ut blandit finibus felis nec pharetra. Duis maximus commodo libero. In hac habitasse platea dictumst. Duis feugiat purus sed magna tempus, suscipit laoreet odio lacinia. Aenean tincidunt sapien at est luctus suscipit. Morbi quis pellentesque nisi. Donec nec est tempor, congue lorem a, egestas est. Phasellus sit amet feugiat justo. Morbi quis venenatis justo.`}</p>
      </SectionTwo>
    </ContainerFluid>
    <ContainerFluid className="py-5" mdxType="ContainerFluid">
      <SectionOne col={3} className="d-none d-lg-block" style={{
        backgroundColor: "lightgray",
        padding: "4rem 0"
      }} mdxType="SectionOne">
        <AnimationRubik mdxType="AnimationRubik" />
      </SectionOne>
      <SectionTwo col={8} mdxType="SectionTwo">
        <p>{`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec risus dui, eleifend ac facilisis in, pretium in sapien. Morbi a sem eget urna laoreet placerat nec a orci. Vivamus quis lectus sit amet turpis commodo viverra in eget urna. Aenean at feugiat leo.`}</p>
        <p>{`Quisque at sodales ex. Mauris eget ultricies ipsum. Cras vel mollis nisi. Duis sed orci quis elit tempus eleifend ut non nisi. Ut blandit finibus felis nec pharetra. Duis maximus commodo libero. In hac habitasse platea dictumst. Duis feugiat purus sed magna tempus, suscipit laoreet odio lacinia. Aenean tincidunt sapien at est luctus suscipit. Morbi quis pellentesque nisi. Donec nec est tempor, congue lorem a, egestas est. Phasellus sit amet feugiat justo. Morbi quis venenatis justo.`}</p>
      </SectionTwo>
    </ContainerFluid>
    <ContainerFluid className="py-5" mdxType="ContainerFluid">
      <SectionOne col={3} className="d-none d-lg-block" style={{
        backgroundColor: "lightgray",
        padding: "8.5rem 0 6.5rem 0"
      }} mdxType="SectionOne">
        <Animation mdxType="Animation" />
      </SectionOne>
      <SectionTwo col={9} mdxType="SectionTwo">
        <p>{`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec risus dui, eleifend ac facilisis in, pretium in sapien. Morbi a sem eget urna laoreet placerat nec a orci. Vivamus quis lectus sit amet turpis commodo viverra in eget urna. Aenean at feugiat leo.`}</p>
        <p>{`Quisque at sodales ex. Mauris eget ultricies ipsum. Cras vel mollis nisi. Duis sed orci quis elit tempus eleifend ut non nisi. Ut blandit finibus felis nec pharetra. Duis maximus commodo libero. In hac habitasse platea dictumst. Duis feugiat purus sed magna tempus, suscipit laoreet odio lacinia. Aenean tincidunt sapien at est luctus suscipit. Morbi quis pellentesque nisi. Donec nec est tempor, congue lorem a, egestas est. Phasellus sit amet feugiat justo. Morbi quis venenatis justo.`}</p>
        <p>{`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec risus dui, eleifend ac facilisis in, pretium in sapien. Morbi a sem eget urna laoreet placerat nec a orci. Vivamus quis lectus sit amet turpis commodo viverra in eget urna. Aenean at feugiat leo.`}</p>
        <p>{`Quisque at sodales ex. Mauris eget ultricies ipsum. Cras vel mollis nisi. Duis sed orci quis elit tempus eleifend ut non nisi. Ut blandit finibus felis nec pharetra. Duis maximus commodo libero. In hac habitasse platea dictumst. Duis feugiat purus sed magna tempus, suscipit laoreet odio lacinia. Aenean tincidunt sapien at est luctus suscipit. Morbi quis pellentesque nisi. Donec nec est tempor, congue lorem a, egestas est. Phasellus sit amet feugiat justo. Morbi quis venenatis justo.`}</p>
      </SectionTwo>
    </ContainerFluid>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      